export default class Metier {
  constructor(startDate, endDate) {
    this.state = {
      startDate : startDate,
      endDate : endDate
    }
  }


  verificationFormulaireReservez(nbr_adulte, nbr_enfant, hebergement, qte, formule) {
    //Verifiez si le nombre d'adulte + nbr enfant > qté max typo
    //Verifie si il ya une Formule

    let diff_days = this.diff_days(new Date(this.state.startDate), new Date(this.state.endDate))

    if (formule === null) {
      return "formule_null"
    }

    if (formule.nights !== diff_days) {
      return "wrong_nights"
    }

    let adulte_max = 0
    let adulte_min = 0
    let enfant_max = 0
    let enfant_min = 0
    let max_participant = 0
    let notFind = true

    let i = 0
    for (let quantite of qte) {
      if (quantite !== null && typeof quantite != "undefined") {
        if (quantite.value > 0) {
          for (let typo of hebergement) {
            if (typo.id === i) {
              notFind = false
              adulte_max += typo.max_adult * quantite.value
              adulte_min += typo.min_adult * quantite.value
              enfant_max += typo.max_enfant * quantite.value
              enfant_min += typo.min_enfant * quantite.value
              max_participant += typo.nb_personne_max * quantite.value
            }
          }
        }
      }
      i++
    }

    if (qte === null || notFind) {
      return "qte_null"
    }
    if (nbr_adulte + nbr_enfant > max_participant) {
      return "too_much_people"
    }
    if (nbr_adulte > adulte_max) {
      return "nbr_adulte_too_much"
    }
    if (nbr_enfant > enfant_max) {
      return "nbr_enfant_too_much"
    }
    if (nbr_adulte < adulte_min) {
      return "nbr_adulte_need_more"
    }
    if (nbr_enfant < enfant_min) {
      return "nbr_enfant_need_more"
    }
  }

  getCommitionWeek(week) {
    let total = 0.0
    let commition = 0.0

    if (week.commition_amount_diviseur) {
      total = parseFloat(week.price) / parseFloat(week.commition_amount)
      return total - parseFloat(week.price)
    }

    if (week.commition_is_purcent) {
      commition = parseFloat(week.price) * (parseFloat(week.commition_amount) / 100)
      return - commition
    }

    return parseFloat(week.commition_amount)
  }

  getCommitionWeekHebergement(week) {
    let total = 0.0
    let commition = 0.0

    if (week.is_divider) {
      total = parseFloat(week.price) / parseFloat(week.commition_amount)
      return total - parseFloat(week.price)
    }

    if (week.is_purcent) {
      commition = parseFloat(week.price) * (parseFloat(week.commition_amount) / 100)
      return commition
    }

    return parseFloat(week.commition_amount)
  }

  getCommitionWeekHebergementPromo(week) {
    let total = 0.0
    let commition = 0.0

    if (week.is_divider) {
      total = parseFloat(week.price_promo) / parseFloat(week.commition_amount)
      return total - parseFloat(week.price_promo)
    }

    if (week.is_purcent) {
      commition = parseFloat(week.price_promo) * (parseFloat(week.commition_amount) / 100)
      return commition
    }

    return parseFloat(week.commition_amount)
  }

  getPriceSaleWeek(week) {
    if (week.is_price_sale) {
      return parseFloat(week.price)
    }
    return parseFloat(week.price) + this.getCommitionWeek(week)
  }

  getPriceSaleHebergement(week) {
    if (!week.is_price_sale) {
      return parseFloat(week.price) - this.getCommitionWeekHebergement(week)
    }
    return parseFloat(week.price)
  }

  getPriceSaleHebergementPromo(week) {
    if (!week.is_price_sale) {
      return parseFloat(week.price_promo) - this.getCommitionWeekHebergementPromo(week)
    }
    return parseFloat(week.price_promo)
  }

  findAgeJunior(formule, all_options) {
    //Si pas de formule séléctionné
    if (formule === null) return 0

    for (let new_inclus of formule.inclus) {
      for (let categories of all_options) {
        for (let options of categories.options) {
          if (options.id === new_inclus.id) {
            return this.findAgeOptionsJuniorOfDate(options.prices)
          }
        }
      }
    }

    return 12
  }

  calculFormuleJunior(formule, all_options, optimized = false) {
    let price_formule = 0.0

    if (optimized) {
      //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let options of all_options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsJuniorOfDate(options.prices)
            }
        }
      }

      return price_formule
    }
    else {
      //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let categories of all_options) {
          for (let options of categories.options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsJuniorOfDate(options.prices)
            }
          }
        }
      }

      return price_formule
    }
    
  }

  // Prix / Pers :
  // Prix Typo + Prix des options inclus
  //
  // Trouvé les prestations inclus dans la formule
  // Trouvé leur prix dans all_options
  // Ajouté l'ensemble des prix des options
  calculFormuleAdulte(formule, all_options, optimized = false) {
    let price_formule = 0.0
    if (optimized) {
      //Si pas de formule séléctionné
      if (formule === null) return 0
  
      for (let new_inclus of formule.inclus) {
        for (let options of all_options) {
            if (options.id === new_inclus.id) {
              let price = this.findPriceOptionsAdulteOfDate(options.prices)
              if (price === -1) {
                return -1
              }
              else {
                price_formule += this.findPriceOptionsAdulteOfDate(options.prices)
              }
            }
        }
      }
  
      return price_formule
    }
    else {
      //Si pas de formule séléctionné
      if (formule === null) return 0
      if (typeof formule.inclus === "undefined") return 0
  
      for (let new_inclus of formule.inclus) {
        for (let categories of all_options) {
          for (let options of categories.options) {
            if (options.id === new_inclus.id) {
              let price = this.findPriceOptionsAdulteOfDate(options.prices)
              if (price === -1) {
                return -1
              }
              else {
                price_formule += this.findPriceOptionsAdulteOfDate(options.prices)
              }
            }
          }
        }
      }
  
      return price_formule
    }

  }

  fixMargeStayWithoutDate(price_perticpant, formule) {
    let total = 0.0

    for (let commition of formule.commitions) {
        if (commition.is_divider) {
          total = price_perticpant / parseFloat(commition.amount.replace(',', '.'))
          return total - price_perticpant
        }

        if (commition.is_purcent) {
          total = price_perticpant
          return (total * (parseFloat(commition.amount.replace(',', '.')) / 100))
        }

        return parseFloat(commition.amount.replace(',', '.'))
    }
    return 0
  }

  fixMargeStay(price_perticpant, formule) {
    let total = 0.0

    //Appliquer la marge par participant
    let startDate = new Date(this.state.startDate)
    let endDate = new Date(this.state.endDate)
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1)
    startDate.setHours(0,0,0,0)
    endDate.setHours(0,0,0,0)

    if (formule === null) return 0
    for (let commition of formule.commitions) {
      let testStart = new Date(commition.from)
      let testEnd = new Date(commition.to)
      testStart.setHours(0,0,0,0)
      testEnd.setHours(0,0,0,0)

      if (startDate.getTime() >= testStart.getTime() && endDate.getTime() <= testEnd.getTime()) {
        if (commition.is_divider) {
          total = price_perticpant / parseFloat(commition.amount.replace(',', '.'))
          return total - price_perticpant
        }

        if (commition.is_purcent) {
          total = price_perticpant
          return (total * (parseFloat(commition.amount.replace(',', '.')) / 100))
        }

        return parseFloat(commition.amount.replace(',', '.'))
      }
    }
    return 0
  }

  findAgeOptionsJuniorOfDate(prices_array, add_one_day = false) {
    let age = 12
    for (let one_grille of prices_array) {
      age = parseInt(one_grille.max_age)
      let from_date = new Date(one_grille.from)
      let selected_date = new Date(this.state.startDate)
      selected_date.setHours(0,0,0,0)
      from_date.setHours(0,0,0,0)

      //"début du séjour" + "durée du séjour" <= "fin du produit" - 1 jour
      if (!add_one_day) {
        selected_date = new Date(selected_date.getFullYear(), selected_date.getMonth(), selected_date.getDate() + 1)
      }

      //Calcul avec prix adulte
      if (from_date.getTime() === selected_date.getTime() && age >= one_grille.min_age && age <= one_grille.max_age) {
        return age
      }
    }
    if (add_one_day) {
      return 0
    }
    else {
      return this.findAgeOptionsJuniorOfDate(prices_array, true)
    }
  }

  findPriceOptionsJuniorOfDate(prices_array, add_one_day = false) {
    let age = 12
    for (let one_grille of prices_array) {
      age = parseInt(one_grille.max_age)
      let from_date = new Date(one_grille.from)
      let selected_date = new Date(this.state.startDate)
      selected_date.setHours(0,0,0,0)
      from_date.setHours(0,0,0,0)

      //"début du séjour" + "durée du séjour" <= "fin du produit" - 1 jour
      if (!add_one_day) {
        selected_date = new Date(selected_date.getFullYear(), selected_date.getMonth(), selected_date.getDate() + 1)
      }

      //Calcul avec prix adulte
      if (from_date.getTime() === selected_date.getTime() && age >= one_grille.min_age && age <= one_grille.max_age) {
        return this.getPriceSaleWeek(one_grille)
      }
    }
    if (add_one_day) {
      return 0
    }
    else {
      return this.findPriceOptionsJuniorOfDate(prices_array, true)
    }
  }

  //Trouvé le prix de l'options a une certaine dates
  findPriceOptionsAdulteOfDate(prices_array, add_one_day = false) {
    let age = 18
    for (let one_grille of prices_array) {
      let from_date = new Date(one_grille.from)
      let selected_date = new Date(this.state.startDate)
      selected_date.setHours(0,0,0,0)
      from_date.setHours(0,0,0,0)

      //"début du séjour" + "durée du séjour" <= "fin du produit" - 1 jour
      if (!add_one_day) {
        selected_date = new Date(selected_date.getFullYear(), selected_date.getMonth(), selected_date.getDate() + 1)
      }

      //Calcul avec prix adulte
      if (from_date.getTime() === selected_date.getTime() && age >= one_grille.min_age && age < one_grille.max_age) {
        return this.getPriceSaleWeek(one_grille)
      }
    }
    if (add_one_day) {
      return -1
    }
    else {
      return this.findPriceOptionsAdulteOfDate(prices_array, true)
    }
  }

  //pour les items de la recherche nous avons pas forcement de date
  //Nous calculons le prix de la formule au date les moins cher
  calculFormuleAdulteWithouDate(formule, all_options, optimized = false) {
    let price_formule = 0

    if (optimized) {
      //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let options of all_options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsAdulteWithoutDate(options.prices)
            }
        }
      }
      return price_formule
    }
    else {
      //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let categories of all_options) {
          for (let options of categories.options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsAdulteWithoutDate(options.prices)
            }
          }
        }
      }
      return price_formule
    }
  }

  calculFormuleJuniorWithouDate(formule, all_options, optimized = false) {
    let price_formule = 0

    if (optimized) {
       //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let options of all_options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsJuniorWithoutDate(options.prices)
            }
          }
      }
      return price_formule
    }
    else {
       //Si pas de formule séléctionné
      if (formule === null) return 0

      for (let new_inclus of formule.inclus) {
        for (let categories of all_options) {
          for (let options of categories.options) {
            if (options.id === new_inclus.id) {
              price_formule += this.findPriceOptionsJuniorWithoutDate(options.prices)
            }
          }
        }
      }
      return price_formule
    }
  }

  findPriceOptionsJuniorWithoutDate(prices_array) {
    let current_price_found = Infinity
    for (let one_grille of prices_array) {
      //Calcul avec prix adulte
      if ( 12 >= one_grille.min_age && 12 <= one_grille.max_age && this.getPriceSaleWeek(one_grille) < current_price_found) {
        current_price_found = this.getPriceSaleWeek(one_grille)
      }
    }
    for (let one_grille of prices_array) {
      //Calcul avec prix 8 ans
      if ( 8 >= one_grille.min_age && 8 <= one_grille.max_age && this.getPriceSaleWeek(one_grille) < current_price_found) {
        current_price_found = this.getPriceSaleWeek(one_grille)
      }
    }
    for (let one_grille of prices_array) {
      //Calcul avec prix 13 ans
      if ( 13 >= one_grille.min_age && 13 <= one_grille.max_age && this.getPriceSaleWeek(one_grille) < current_price_found) {
        current_price_found = this.getPriceSaleWeek(one_grille)
      }
    }
    return current_price_found === Infinity ? 0 : current_price_found
  }

  findPriceOptionsAdulteWithoutDate(prices_array) {
    let current_price_found = Infinity
    for (let one_grille of prices_array) {
      //Calcul avec prix adulte
      let from_date = new Date(one_grille.from)
      from_date.setHours(0,0,0,0)
      let now = new Date()
      now.setHours(0,0,0,0)
      if ( 18 >= one_grille.min_age && 18 < one_grille.max_age && this.getPriceSaleWeek(one_grille) < current_price_found && from_date > now) {
        current_price_found = this.getPriceSaleWeek(one_grille)
      }
    }
    return current_price_found === Infinity ? 0 : current_price_found
  }


  //Verifie si la typo possede bien la formule
  checkIfFormuleIsOnTypo(typo, formule) {
    //pas de formule séléctionné
    if (formule === null) return true

    //verifie la formule séléctionné
    for (let typo_acceptable of formule.typologies_ids) {
      if (typo.id === typo_acceptable) {
        return true
      }
    }
    return false
  }

  findBestPromoPriceOfTypo(typo) {
    let best = 0.0
    let current_price = 0.0
    for (let one_grille of typo.grille) {
      current_price = this.getPriceSaleHebergementPromo(one_grille)
      let from_date = new Date(one_grille.from)
      from_date.setHours(0,0,0,0)
      let now = new Date()
      now.setHours(0,0,0,0)
      if ((current_price < best || best === 0) && from_date > now ) {
        best = current_price
      }
    }
    return best
  }

  findBestPriceOfTypo(typo) {
    let best = 0.0
    let current_price = 0.0
    for (let one_grille of typo.grille) {
      current_price = this.getPriceSaleHebergement(one_grille)
      let from_date = new Date(one_grille.from)
      from_date.setHours(0,0,0,0)
      let now = new Date()
      now.setHours(0,0,0,0)
      if ((current_price < best || best === 0) && from_date > now ) {
        best = current_price
      }
    }
    return best
  }

  //Trouve le prix dans la grille de la typo
  findPriceOfDate(typo) {
    for (let one_grille of typo.grille) {
      let from_date = new Date(one_grille.from)
      let selected_date = new Date(this.state.startDate)
      selected_date.setHours(0,0,0,0)
      from_date.setHours(0,0,0,0)
      if (from_date.getTime() === selected_date.getTime()) {
        return this.getPriceSaleHebergement(one_grille)
      }
    }
    //Si pas de grille trouvé
    return -1
  }

  //Trouve le price_promo dans la grille de la typo
  findPromoPriceOfDate(typo) {
    for (let one_grille of typo.grille) {
      let from_date = new Date(one_grille.from)
      let selected_date = new Date(this.state.startDate)
      selected_date.setHours(0,0,0,0)
      from_date.setHours(0,0,0,0)
      if (from_date.getTime() === selected_date.getTime()) {
        if (one_grille.price_promo === 0) return 0
        return this.getPriceSaleHebergementPromo(one_grille)
      }
    }
    return 0
  }

  //Trouve le prix d'une formule
  findPricePrestations(formule, prestations) {
    for (let potentiel_formule of prestations.formule) {
      if (potentiel_formule.title.fr === formule) {
        return potentiel_formule.price
      }
    }
    return 0
  }

  //retourne l'objet formule en fonction du title de la formule sélectionné
  returnFormuleObject(title_formule, all_formule) {
    for (let new_formule of all_formule) {
      if (title_formule === new_formule.title.fr) {
        return new_formule
      }
    }
    return null
  }

  //Calcul le prix du log + formule
  calcul_final_price(price_prestations_per_person, margeStay, number_participant) {
    return (price_prestations_per_person + (margeStay / number_participant))
  }

  //trouve le départ le plus proche dans la grille
  findCloserDate(all_typo) {
    let now = new Date()
    let closest = Infinity

    now.setHours(0,0,0,0)
    for (let one_typo of all_typo) {
      for (let one_grille of one_typo.grille) {
        let from_date = new Date(one_grille.from)
        from_date.setHours(0,0,0,0)
        if (one_grille.is_bookable === true && from_date >= now && (from_date < new Date(closest) || from_date < closest)) {
          closest = from_date
        }
      }
    }
    return closest
  }

  GetFormattedDate(date) {
    try {
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let year = date.getFullYear();
      if (month.toString().length < 2) month = '0' + month;
      if (day.toString().length < 2) day = '0' + day
      return day + "/" + month + "/" + year;
    }
    catch(e) {
      console.log(e)
    }
  }

  formatDateForSoapCall(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  formateDateFrench(date) {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day

    return [day, month, year].join('-') 
  }

  diff_years(dt2, dt1) {
    let diff =(dt2.getTime() - dt1.getTime()) / 1000
    diff /= (60 * 60 * 24)
    return (Math.abs(Math.round(diff/365.25)))
  }

  diff_days(dt2, dt1) {
    // To calculate the time difference of two dates
    let Difference_In_Time = dt2.getTime() - dt1.getTime()

    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
    return (Math.abs(Math.round(Difference_In_Days)))
  }

  handlePriceJuniorsAndSportifs(sejour, formule) {
    let price
    if (sejour._source.hebergement[0].type_price === "Participant") {
      formule = sejour._source.prestations.formule[0]
    }
    let date_from = new Date()
    let date_to = new Date()
    let number_participant = 1
    let hebergement = sejour._source.hebergement

    price = this.recherchePrixPers(formule, hebergement, sejour._source.prestations.all_options, date_from, date_to, number_participant)
    price = Math.round(price.price)
    return price
  }

  returnFormuleNotSelected(all_formule) {
    if (typeof all_formule === "undefined") return null
    let formule = null
    for (let new_formule of all_formule) {
      if (new_formule.title.fr === "Hébergement") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Demi-Pension") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Pension Complète") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "All Inclusive") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Village Club Tout Compris") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Pension Complète + Forfait") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Hébergement + Activité(s) Sportive(s) en Court-séjour") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Hébergement + Activité(s) Sportive(s)") {
        formule = new_formule
        break
      }
      if (new_formule.title.fr === "Hôtel + Parc de Loisirs") {
        formule = new_formule
        break
      }
    }

    return formule
  }

  handlePrice(state, source, language, location) {
    let participants = {adult : typeof state.adulte !== "undefined" ? parseInt(state.adulte) : 1, child : typeof state.child !== "undefined" ? parseInt(state.child) : 0}
    /*let price = source.min_price*/
    let price = 0
    let date_changed = false
    let price_without_promo = 0
    let detail_price = language.langue === "fr" ? "prix / logement" : "price / log"
    
    
    // Si l'hebergement contient des demi-pension et des pensions et qu'aucune formule est selectionné
    // Alors choisir pour calculer
    // demipension
    // pension
    // all_inclusive

    let formule = ""
    if (state.type_formules.length === 0) {
      formule = this.returnFormuleNotSelected(source.prestations.formule)
    }

    //Si aucune formule trouvé au dessus et qu'il n'y a qu'une formule possible
    if (formule === "" && state.type_formules.length === 0 && source.prestations.formule.length === 1) {
      formule = source.prestations.formule[0]
    }

    try {
      /*if(source.hebergement.length > 0) {
        console.info(source.hebergement[0].type_price)
      }*/

      // eslint-disable-next-line no-mixed-operators
      if ((state.type_formules.length > 0 && state.type_formules[0].length > 0) ||
          // eslint-disable-next-line no-mixed-operators
          source.hebergement.length > 0 && (typeof source.hebergement[0].type_price !== undefined && (source.hebergement[0].type_price === "Participant")) ||
          formule !== "") {

        //Si c'est un sejour particpant on lui applique une fomrule
        if (source.hebergement.length > 0 && (typeof source.hebergement[0].type_price !== undefined && (source.hebergement[0].type_price === "Participant"))) {
          formule = source.prestations.formule[0]
        } else if (formule === "") {
          formule = this.returnFormuleObject(state.type_formules[0].toString(), source.prestations.formule)
        }

        let date_from = new Date(state.event_date_from)
        let date_to = new Date(state.event_date_to)
        let number_participant = formule && (formule.title.fr === "Hébergement") ? 1 : (participants.adult === 0 && participants.child === 0 ? 1 : (participants.adult + participants.child))
        let hebergement = source.hebergement
        price = this.recherchePrixPers(formule, hebergement, source.prestations.all_options, date_from, date_to, number_participant, participants.adult === 0 && participants.child === 0 ? 1 : (participants.adult + participants.child))
        date_changed = price.date_change
        price_without_promo = price.promo_best
        price = Math.round(price.price)
        detail_price = language.langue === "fr" ? "prix / personne" : "price / guest"

        if (formule && (formule.title.fr === "Hébergement")) {
          detail_price = language.langue === "fr" ? "prix / logement" : "price / log"
        }

        if (price === 0 || price === -1) {
          detail_price = language.langue === "fr" ? "prix / logement" : "price / log"
        }
      }
    }
    catch (e) {
      console.log(e)
    }

    if (typeof document !== "undefined") {
      return {
        price : price,
        date_changed : date_changed,
        detail_price : detail_price,
        url : window.location.search,
        price_without_promo : Math.round(price_without_promo) > 0 ? Math.round(price_without_promo) : 0
      }
    }
    else {
      location = location.split(/[?#]/)[1]
      let query = ""
      if (typeof location !== "undefined") {
        query = "?" + location
      }
      return {
        price : price,
        date_changed : date_changed,
        detail_price : detail_price,
        url : query,
        price_without_promo : Math.round(price_without_promo) > 0 ? Math.round(price_without_promo) : 0
      }
    }
  }

  //verifier les dates
  recherchePrixPers(formule, hebergement, all_options, date_from, date_to, nbr_participant, real_number_particpant, arrondi_date = false) {
    let no_date = false

    if (date_from.toDateString() === "Invalid Date" || date_to.toDateString() === "Invalid Date") {
      no_date = true
    }

    //verifiez si c'est du samedi au samedi pour calculer avec les dates
    if (date_from.getDay() !== 6 || date_to.getDay() !== 6) {
      no_date = true
    }

    //Verifie qu'il y est 7 jours entre les deux dates
    // if (this.diff_days(date_from, date_to) !== 7 && this.diff_days(date_from, date_to) !== 8) {
    //   no_date = true
    // }

    //Si pas de date valide on retourne le min price de la formule
    if (no_date) {
      try {
        return {
          date_change : false,
          price : parseFloat(formule.min_price.price),
          promo_best : parseFloat(formule.min_price.price_with_out_promo) < parseFloat(formule.min_price.price) ? parseFloat(formule.min_price.price_with_out_promo) : 0
        }
      }
      catch (e) {
        return {
          date_change : false,
          price : 0,
          promo_best : 0
        }
      }
    }

    if (formule === null) return
    if (typeof formule.inclus === "undefined") formule.inclus = []

    let price_prestations
    let price_log
    let promo_price_log
    let number_participant
    let price_perticpant
    let margeStay
    let promo_margeStay = 0
    let final_price
    let promo_price_perticpant
    let final_price_whithout_promo
    let date_pas_trouve = false

    let best = Infinity
    let promo_best = Infinity

    for (let newTypo of hebergement) {
      if (this.checkIfFormuleIsOnTypo(newTypo, formule) === true) {
          //Si prix log + prix formule === 0 alors il y a un pb
        let sejour_junior = false

        number_participant = typeof nbr_participant === "undefined" ? newTypo.nb_personne_max : parseInt(nbr_participant)

        if (newTypo.max_adult === 0 && newTypo.max_enfant > 0) {
          sejour_junior = true
          number_participant = typeof nbr_participant === "undefined" ? 1 : parseInt(nbr_participant)
        }

        let location_seche =  formule !== null && formule.inclus.length > 0 ? false : true
        if (no_date) {
          price_log = parseFloat(this.findBestPriceOfTypo(newTypo))
          promo_price_log = parseFloat(this.findBestPromoPriceOfTypo(newTypo))
        }
        else {
          price_log = parseFloat(this.findPriceOfDate(newTypo))
          promo_price_log = parseFloat(this.findPromoPriceOfDate(newTypo))
          if (price_log <= 0) {
            date_pas_trouve = true
          }
        }

        let has_promo = true
        if (promo_price_log === 0 || promo_price_log === price_log) {
          has_promo = false
        }

        if (sejour_junior) {
          if (no_date) {
            price_prestations = parseFloat(this.calculFormuleJuniorWithouDate(formule, all_options, false))
          }
          else {
            price_prestations = parseFloat(this.calculFormuleJunior(formule, all_options, false))
          }
        }
        else {
          if (no_date) {
            price_prestations = parseFloat(this.calculFormuleAdulteWithouDate(formule, all_options, false))
          }
          else {
            price_prestations = parseFloat(this.calculFormuleAdulte(formule, all_options, false))
          }
        }

        price_perticpant = (price_log + price_prestations * number_participant) / number_participant
        promo_price_perticpant = (promo_price_log + price_prestations * number_participant) / number_participant

        if (!no_date) {
          margeStay = this.fixMargeStay(price_perticpant * number_participant, formule)
          promo_margeStay = this.fixMargeStay(promo_price_perticpant * number_participant, formule)
        }
        else {
          margeStay = this.fixMargeStayWithoutDate(price_perticpant * number_participant, formule)
          promo_margeStay = this.fixMargeStayWithoutDate(promo_price_perticpant * number_participant, formule)
        }

        margeStay = margeStay === Infinity ? 0 : margeStay
        promo_margeStay = promo_margeStay === Infinity ? 0 : promo_margeStay

        if (location_seche) {
          price_log += margeStay
          if (promo_price_log !== 0) {
            promo_price_log += promo_margeStay
          }
        }

        final_price = this.calcul_final_price(has_promo ? promo_price_perticpant : price_perticpant, has_promo ? promo_margeStay : margeStay, number_participant)
        final_price_whithout_promo = this.calcul_final_price(price_perticpant, margeStay, number_participant)
        
        //Junior
        if (sejour_junior) {
          if (final_price < best) {
            best = final_price
          }
          if (has_promo) {
            if (final_price_whithout_promo < best) {
              best = final_price_whithout_promo
            }
          }
        }

        //Adulte
        if (final_price_whithout_promo < best && price_log !== -1 && final_price_whithout_promo > 0 && !isNaN(final_price_whithout_promo)) {
          if (real_number_particpant <= newTypo.max_adult && real_number_particpant >= newTypo.min_adult) {
            best = final_price_whithout_promo
          }
        }
        if (has_promo) {
          if (final_price < best && real_number_particpant <= newTypo.max_adult && real_number_particpant >= newTypo.min_adult && final_price > 0 && !isNaN(final_price)) {
            best = final_price
            if (location_seche === true || no_date === false) {
              promo_best = final_price_whithout_promo
            }
          }
        } 
      }
    }
    
    if ((best === Infinity || isNaN(best)) && arrondi_date === false) {

        //Trouvé la date la plus proche par rapport a celle séléctionné
        for (let newTypo of hebergement) {
          let closest = Infinity
          for (let one_grille of newTypo.grille) {
            let from_date = new Date(one_grille.from)
            from_date.setHours(0,0,0,0)
            if (one_grille.is_bookable === true && from_date >= new Date(this.state.startDate) && (from_date < new Date(closest) || from_date < closest)) {
              closest = from_date
            }
          }

          let selected_date = new Date(closest)
          selected_date.setHours(0,0,0,0)
            for (let one_grille of newTypo.grille) {
              let from_date = new Date(one_grille.from)
              from_date.setHours(0,0,0,0)
              if (from_date.getTime() === selected_date.getTime()) {
                let endDate = new Date(one_grille.to)
                endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1)
                this.state.endDate = new Date(endDate)
              }
            }

            if (closest !== Infinity) {
              this.state.startDate = new Date(closest)
            }
        }
        return this.recherchePrixPers(formule, hebergement, all_options, date_from, date_to, nbr_participant, real_number_particpant, true)
    }

    return {
      date_change : date_pas_trouve,
      price : best === Infinity ? 0 : best,
      promo_best : promo_best !== Infinity ? promo_best : 0
    }
  }
}
